<!--
 * @Author: gaojingran
 * @Date: 2021-03-23 16:56:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-06 13:43:22
 * @Description: Tag标签
-->

<style lang="less" scoped>
.neo-tag {
  display: inline-block;
  padding: 0 16px;
  height: 22px;
  line-height: 22px;
  border-radius: 22px;
  text-align: center;
  font-size: @font_size_1;
  &.mini {
    height: 19px;
    line-height: 16px;
    padding: 0 6px;
  }
  &.shape {
    border-radius: 8px 0px 8px 0px !important;
    border: none !important;
  }
  &.default {
    border: 1px solid @background-color_4;
    color: @text_color_2;
    background-color: @background-color_4;
  }
  &.primary {
    border: 1px solid lighten(@primary-color, 26%);
    color: @primary-color;
    background-color: lighten(@primary-color, 33%);
  }
  &.warning {
    border: 1px solid lighten(@warning-color, 26%);
    color: @warning-color;
    background-color: lighten(@warning-color, 40%);
  }
  &.danger {
    border: 1px solid lighten(@error-color, 26%);
    color: @error-color;
    background-color: lighten(@error-color, 32%);
  }
  &.success {
    border: 1px solid lighten(@success-color, 26%);
    color: @success-color;
    background-color: lighten(@success-color, 40%);
  }
  &.pure-bw {
    border: 1px solid #000000;
    color: #000000;
    background-color: @background-color_4;
  }
}
</style>

<template>
  <span :class="['neo-tag', size, type, shape ? 'shape' : '', className]" :style="{ styles }">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'NeoTag',
  props: {
    // tag形状
    shape: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['normal', 'mini'].indexOf(value) !== -1
      },
    },
    // 状态
    type: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'primary', 'warning', 'danger', 'success', 'pure-bw'].indexOf(value) !== -1
      },
    },
    styles: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
  },
}
</script>
